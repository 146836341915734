<template>
  <div class="modal" v-bind:class="{'is-active' : modalShow}">
    <div class="modal-background"></div>
    <div class="modal-content" style="width: 95vw; height: 95vh; overflow: hidden;">
      <iframe :src="docUrl" style="width: 100%; height: 100%"></iframe>
    </div>
    <button class="modal-close is-large" aria-label="close" @click.prevent="close" type="button" style='left: 45px; top: 25px'></button>
  </div>
</template>

<script>
  export default {
    data: () => {
      return {
        docUrl: "",
        modalShow: false
      }
    },
    methods: {
      show(newUrl) {
        this.docUrl = newUrl;
        this.modalShow = true;
      },
      hide() {
        this.modalShow = false;
      },
      close() {
        this.hide()
      }
    }
  };
</script>
