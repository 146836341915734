import { mobileCheck } from './mobile-or-desktop'
import { getMobileOrigin } from './get-mobile-origin'

export const checkMobileAndRedirect = (mobileRoute, desktopRoute = null) => {
  const device = mobileCheck() ? "Mobile" : "Desktop";
  if (device === "Mobile") {
    const url = getMobileOrigin() + mobileRoute
    window.location.replace(url)
  } else {
    desktopRoute && window.location.replace(desktopRoute)
  }
}
