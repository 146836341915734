

export const PrussianBluePath = "/assets/addy_logo_rgb_prussian_blue-710694a0d56e316216b0ab1009c267dee33e36e11cf4a79b323866c4aa7edd6a.png";
export const SignupSuccessImagePath = "/assets/signup/success-image-88083af3057ffa4c3bb58df2f5a632b81155e2902ef93f9f6869f644f36f819f.jpg";
export const SignupSetupImagePath = "/assets/signup/setup-image-ff70cdec2529daeb3315d35072087d310ed1dca6a3980a522ea609750a9da967.jpg";
export const SignupVerifyEmailImagePath = "/assets/signup/verify-email-image-04de49e4bf85aa61f3c9c6ce66076de9330b87b98ef19fede27f1f02334785bc.jpg";
export const NotAvailableRegionImagePath = "/assets/signup/not-available-region-image-b4b0917b43762900d0c40054e690d0d06f164ba5cd67d2494d0f3880606acc09.jpg";
export const SignupBuildYourProfilePath = "/assets/signup/build_your_profile-9100a491df514d53cdcc0cd5dcba8e3832402eef90818db8921a4d9ad47e8256.jpg";
export const SignupAddressImagePath = "/assets/signup/residential_address_image-5446cb49ed3518ccb0bcac4ecf09202d4703108a83bdf3b560cdc54bb2c0c979.jpg";
export const SignupWalletImagePath = "/assets/signup/fund_wallet_image-5bdce33ca08e0be39e812a0dde50aaf1b8837d8e3895d741b181f2fc50742c8e.jpg";
export const SignupCreateIcon1= "/assets/signup/create-account-icon1-a924f9064c0b77254f63dc3940639ae12d272d63a8f6826b71967da36c3ded63.png";
export const SignupCreateIcon2= "/assets/signup/create-account-icon2-0670b0d201ebcb1c01a5be0816ae3c5f41156ddd1ebe29f4747756704dce8e4f.png";
export const SignupCreateIcon3= "/assets/signup/create-account-icon3-bcdf2b7cfd7e21a8f7c0c6b99e686173a0668294686dce0c720533dd5ec3b36e.png";
export const tertiaryStat1= "/assets/property-tertiary-stat-1-e813fb598a026a2c8d3db2503a85f67c5b8c4cad10dea278e4d21e84631c1baa.svg";
export const tertiaryStat2= "/assets/property-tertiary-stat-2-c1d502b37ce7a4e5699023c927fb924f3f51b2733d64fafec4d9e3d10a1d26bd.svg";
export const sinSuccessImagePath= "/assets/t5_success-661033261b8b1731a031fff74fd50ca1f31a6b993f78e41322c4af1273151364.svg";
export const GoogleButtonImagePath= "/assets/signup/google-sso-cfe113f72f7ee6e4edf7e168071d43c636ff08e9ff71dc7bb6cbecd2c9d08938.svg";
export const AppleButtonImagePath= "/assets/signup/apple-sso-ab2a2f3f29743944b0f35baa7a34b03ffb3e82bd91977f14da88a4a452932c74.svg";
export const DiscordButtonImagePath= "/assets/signup/discord-sso-5c8ff83862645e4c9d51c421ad654c8c8f071a0ceda61a00ac57d23ab08c13f2.svg";
