export const getMobileOrigin = () => {
  switch (window.location.hostname) {
  case 'internal.addyinvest-integration.com':
    return 'https://canada.addyinvest-integration.com/'
  case 'internal.addyinvest-staging.com':
    return 'https://canada.addyinvest-staging.com/'
  case 'internal.addyinvest.com':
  case 'vancouver.addyinvest.com':
    return 'https://canada.addyinvest.com/'
  default:
    return 'http://localhost:8081/'
  }
}
