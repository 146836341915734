<template>
    <standard-modal ref="modal" :title="title">
        <h3>
            {{errorMessage}}
        </h3>
        <div class="field is-grouped">
            <div class="control is-centered">
                <button class='button' @click.prevent="$refs.modal.close()" type="button">
                    Ok
                </button>
            </div>
        </div>
    </standard-modal>
</template>

<script>
  import StandardModal from './standard-modal.vue';

  export default {
    props: ['title'],
    components: {StandardModal},
    data: () => {
      return {errorMessage: ""}
    },
    methods: {
      show(message) {
        this.errorMessage = message;
        this.$refs.modal.show();
      }
    }
  };
</script>
<style scoped>
.is-centered {
  margin: 8px auto;
}
</style>
