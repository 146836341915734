import _ from 'lodash';
import axios from './axios-csrf';

const FormMixins = {
  methods: {
    dateFormatter(date) {
      const year = `${date.getFullYear()}`.padStart(4, '0');
      const month = `${date.getMonth() + 1}`.padStart(2, '0');
      const stringDate = `${date.getDate()}`.padStart(2, '0');

      return `${year}-${month}-${stringDate}`;
    },
    timeFormatter(date) {
      const hours = `${date.getHours()}`.padStart(2, '0');
      const minutes = `${date.getMinutes()}`.padStart(2, '0');

      return `${hours}:${minutes}`;
    },
    classForField(field) {
      if (this.fields[field] && this.fields[field].valid) {
        return 'is-success';
      } else if (this.errors.has(field) && this.fields[field].touched) {
        return 'is-danger';
      } else {
        return '';
      }
    },
    firstErrorFor(field) {
      if (this.errors.has(field) && this.fields[field].touched) {
        return this.errors.first(field);
      } else {
        return '';
      }
    },
    failedXHR(xhr) {
      if (xhr.response.data && xhr.response.data.error) {
        const msg = xhr.response.data.error == 'Auth0::RequestTimeout' ? 'We are experiencing temporary interruption due to a third party service outage. Sorry for the inconvenience, please try again later.' : xhr.response.data.error;
        this.$refs.failModal.show(msg);
      } else {
        this.$refs.failModal.show("Sorry. An unknown error occurred.");
      }
    },
    updateInvestor(investorData) {
      return axios.put('/api/investors/me',
        {investor: investorData}
      ).catch(this.failedXHR.bind(this));
    },
    revalidate() {
      this.$validator.validateAll();
      this.$forceUpdate();
    },
    shortFileName(long_url) {
        try {
            return long_url.split("?")[0].split("/").pop();
        }
        catch(err) {
            return long_url.substring(long_url.lastIndexOf("/") + 1);
        }
    }
  },
  computed: {
    formValidated() {
      return this.errors.items.length == 0 &&
        _.every(Object.keys(this.fields), (key => this.fields[key].validated)) &&
        _.every(Object.keys(this.fields), (key => this.fields[key].valid));
    },
  }
};

export default FormMixins;
