<template>
    <form @submit.prevent="submit">
        <div class="title">
            Log in
        </div>
        <div class="subtitle">
            New to addy?&nbsp;
            <a :href="signupDirectionPath" class="has-text-primary">Sign up now &gt;</a>
        </div>
        <b-field label="Email"
                 :type="classForField('email')"
                 :message="firstErrorFor('email')">
            <b-input v-model="login.email"
                     v-validate="'required|email'"
                     data-vv-delay="200"
                     data-vv-validate-on="blur"
                     v-bind:class="classForField('email')"
                     name="email"/>
        </b-field>
        <b-field label="Password"
                 :type="classForField('password')"
                 :message="firstErrorFor('password')">
            <b-input type="password"
                     password-reveal
                     v-validate="'required'"
                     v-bind:class="classForField('password')"
                     v-model="login.password"
                     @keyup.enter.native="submit"
                     name="password"/>
        </b-field>
        <promise-loading-button class="has-text-primary" :action="changePassword" :disabled="disableChangePasswordButton">
            Change Password
        </promise-loading-button>
        <div class="actions">
            <promise-loading-button ref="loginButton"
                                    :action="loginInvestor"
                                    :disabled="disableLoginButton"
                                    class="button is-primary is-fullwidth">
                Log in
            </promise-loading-button>
            <social-buttons mode="login"></social-buttons>
            <fail-message-modal ref="failModal" title="Login Failed"/>
            <fail-message-modal ref="changePasswordFailedModal" title="Change Password Failed"/>
            <fail-message-modal ref="changePasswordModal" title="Password Change"/>
        </div>
    </form>
</template>

<script>
  import FormMixins from '../utils/form-mixins';
  import RedirctToMobileMixins from '../utils/redirect-to-mobile-mixins';
  import {redirectTo} from '../utils/verification_utils';
  import axios from '../utils/axios-csrf';
  import {PromiseLoadingButton, FailMessageModal} from '../components';
  import {initMixPanel} from '../utils/mixPanel'
  import mixpanel from 'mixpanel-browser';
  import SocialButtons from '../components/social-buttons.vue';

  export default {
    mixins: [FormMixins, RedirctToMobileMixins],
    components: {PromiseLoadingButton, FailMessageModal, SocialButtons},
    data() {
      return {
        login: { email: null, password: null },
        returnToPath: null,
        changePasswordDisabled: false,
      }
    },
    created() {
      this.checkMobileAndRedirect('login')
      initMixPanel(mixpanel)
      if (this.$route.query.returnTo) {
        this.returnToPath = this.$route.query.returnTo;
      }
    },
    mounted() {
      if($cookies.isKey("last_login_error")){
        const lastLoginError = $cookies.get("last_login_error")
        this.$refs.failModal.show(lastLoginError.replace(/\+/g, ' '))
        let sharedCookieDomain = window.location.hostname.replace(/(.*?)(\w+\.\w+)$/g,'$2')
        $cookies.remove("last_login_error", "/", sharedCookieDomain)
      }
    },
    computed: {
      signupDirectionPath(){
        return '/signup/create'
      },
      signupPath() {
        let returnQuery = '';
        if (this.returnToPath) {
          returnQuery = `?fromPage=${this.returnToPath}`;
        }
        return `/signup${returnQuery}`;
      },
      disableLoginButton() {
        return this.errors.items.length !== 0 || !this.login.email || !this.login.password;
      },
      disableChangePasswordButton() { return this.changePasswordDisabled; },
    },
    methods: {
      // maybe a bit of a hack, this actually sets a rails session
      // which logs you into the whole app
      // a real api would give you a token or something.
      submit() {
        this.$refs.loginButton.click();
      },
      loginInvestor() {
        return axios.post('/api/v2/public/authorizations/sign_in', this.login)
          .then(this.loginSuccess.bind(this))
          .catch(this.failedXHR.bind(this));
      },
      changePassword() {
        if( !this.login.email ) {
          this.$refs.changePasswordFailedModal.show('Please input an email!');
          return Promise.reject(false);
        }
        this.changePasswordDisabled = true;
        setTimeout(() => { this.changePasswordDisabled = false }, 60000);
        return axios.post('/api/v2/public/authorizations/reset_password', { email: this.login.email }).then(
          () => { this.$refs.changePasswordModal.show('Password Reset! Please check your email for instructions.'); }
        ).catch(this.passwordResetFailure.bind(this));
      },
      loginSuccess() {
        document.cookie = `timezone = ${new Date().getTimezoneOffset()}`
        mixpanel.identify(this.login.email);
        mixpanel.people.set({
          '$email': this.login.email,
          'USER_ID': this.login.email
        });
        redirectTo(this.redirectPath());
      },
      redirectPath() {
        return this.returnToPath || "/dashboard";
      },
      passwordResetFailure(xhr) {
         if(xhr.response && xhr.response.data && xhr.response.data.message) {
             if(xhr.response.data.message.includes("invalid_query_string")){
                 this.$refs.changePasswordFailedModal.show("The format of the email provided is incorrect. Please try again.");
             } else {
                 this.$refs.changePasswordFailedModal.show(xhr.response.data.message);
             }
        }  else {
             this.$refs.changePasswordFailedModal.show("An unknown error occurred and the developers have been notified.");
             throw xhr;//might not actually be an xhr
         }
      }
    }
  }
</script>
