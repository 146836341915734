<template>
    <div class="create-investor-right-pane">
        <article class="level">
            <figure class="level-item">
                <p class="image">
                    <img :src="SignupCreateIcon1"/>
                </p>
            </figure>
        </article>
        <article class="level">
            <figure class="level-item">
                <p class="image">
                    <img :src="SignupCreateIcon2"/>
                </p>
            </figure>
        </article>
        <article class="level">
            <figure class="level-item">
                <p class="image">
                    <img :src="SignupCreateIcon3"/>
                </p>
            </figure>     
        </article>
    </div>
</template>

<script>
  import {SignupCreateIcon1, SignupCreateIcon2, SignupCreateIcon3} from "../utils/asset-image-paths.js.erb";

  export default {
    data() {
      return {
        SignupCreateIcon1, SignupCreateIcon2, SignupCreateIcon3
      }
    }
  }
</script>
