<template>
    <div @click="show">
          <span v-if='hidden'>
              ********** (hidden - click to reveal)
          </span>
          <span v-else>
              <slot></slot>
          </span>
        </div>
</template>

<script>
import _ from 'lodash';

export default {
  props: [],
  data: () => {
    return {hidden: true}
  },
  methods: {
    show() {
      this.hidden = false;
      let app = this;
      _.delay(() => {
        app.hidden = true;
      }, 60*1000)
    }
  }
};
</script>
