<template>
    <div class="social-buttons-container" v-if="singleSignOnEnabled">
      <h4>{{buttonGroupHeading}}</h4>
      <div class="social-buttons buttons">
        <a class="siwa-link" :href="siwaPath"><img :src="AppleButtonImagePath"></a>
        <a v-if='false' class="discord-link" :href="discordPath"><img :src="DiscordButtonImagePath"></a>
        <a class="google-link" :href="googlePath"><img :src="GoogleButtonImagePath"></a>
      </div>
    </div>
</template>

<style lang="scss" scoped>
.social-buttons-container{
  text-align: center;
  margin: 48px 0;
  h4{
    font-weight: 600;
    color: #2D2926;
    font-size: 18px;
  }
  .social-buttons {
    justify-content: center;
    margin-top: 8px;
    a img{
      margin: 0 6px;
      display:inline-block;
      vertical-align: middle;
      height: 52px;
    }
    a.google-link img{
      filter: drop-shadow(0px 0px 1.05px rgba(0, 0, 0, 0.084)) drop-shadow(0px 1.05px 1.05px rgba(0, 0, 0, 0.168));
    }
  }
}
</style>

<script>
  import {GoogleButtonImagePath, AppleButtonImagePath, DiscordButtonImagePath} from '../utils/asset-image-paths';
  import axios from '../utils/axios-csrf';

  export default {
    props: ['mode'],
    data() {
      return {
        AppleButtonImagePath,
        DiscordButtonImagePath,
        GoogleButtonImagePath,
        DiscordButtonImagePath,
        singleSignOnEnabled: false,
        SocialButtonPaths: '{}'
      }
    },
    mounted() {
      // requires planted html outside of vue-scope
      // it's either this, or an extra ajax call
      const dataset = document.querySelector('#investor-details').dataset
      this.SocialButtonPaths =  dataset.socialButtonPaths
      this.getToggle()
    },
    computed: {
      siwaPath() {
        return JSON.parse(this.SocialButtonPaths)['siwa']
      },
      googlePath() {
        return JSON.parse(this.SocialButtonPaths)['google']
      },
      discordPath() {
        return JSON.parse(this.SocialButtonPaths)['discord']
      },
      buttonGroupHeading() {
        if (this.mode === 'login') {
          return 'Or login with:'
        } else {
          return 'Or sign up with:'
        }
      }
    },
    methods: {
      getToggle() {
        axios.get('/api/v1/flipper/enabled?feature=single_sign_on')
        .then((result) => {
          if (result.data.success) this.singleSignOnEnabled = result.data.data.enabled
        })
      }
    }
  }
</script>
