<template>
    <div id="imby-pane-app" class="is-fullheight">
        <imby-pane-navigation/>
        <div class="section is-fullheight has-flash-on-IE">
            <div v-if="showFlashWithIE" class="notification is-warning is-marginless">
                <button type="button" class="delete" @click="showFlashWithIE = false"></button>
                <div class="has-text-centered">Please use the Google Chrome browser for better accessibility.</div>
            </div>
            <div class="columns is-fullheight">
                <div class="column left-pane">
                    <login-form/>
                </div>
                <div class="column right-pane is-hidden-mobile">
                    <create-investor-right-pane/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import {FailMessageModal, ImbyPaneNavigation} from '../components';
  import LoginForm from './login-form';
  import CreateInvestorRightPane from '../signup/create-investor-right-pane';

  export default {
    components: {LoginForm, FailMessageModal, ImbyPaneNavigation, CreateInvestorRightPane},
    data() {
        return {
            showFlashWithIE: false
        }
    },
    created() {
        const userAgent = window.navigator.userAgent;
        if (userAgent.indexOf('Trident') > -1 || userAgent.indexOf('MSIE') > -1) this.showFlashWithIE = true;
    }
  };
</script>
<style>
#imby-pane-app {
    min-height: 100vh;
}
#imby-pane-app .has-flash-on-IE {
    padding-top: 18px;
    height: 100vh;
}
#imby-pane-app .has-flash-on-IE .media-content {
    text-align: center;
}
#imby-pane-app .column.right-pane {
    background-color: #fff;
}
@media screen and (max-width: 768px) {
    #imby-pane-app .has-flash-on-IE {
        background: #F6F6F6;
    }
}
</style>
