<template>
    <b-field :label="label"
             :type="classForField"
             :message="firstErrorForField">
        <b-input v-bind="$attrs"
                 v-bind:class="classForField"
                 :name="field"
                 :data-vv-name="field"/>
    </b-field>
</template>

<script>

  export default {
    inheritAttrs: false,
    props: ['label', 'field'],
    data: () => {
      return {}
    },
    computed: {
      classForField() {
        return this.$parent.classForField(this.field);
      },
      firstErrorForField() {
        return this.$parent.firstErrorFor(this.field);
      }
    }
  };
</script>
