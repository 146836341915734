const initMixPanel = (mixpanel) => {
  // vancouver.addyinvest.com will be removed later
  const isProduction = location.hostname === 'internal.addyinvest.com' || location.hostname === 'vancouver.addyinvest.com';
  const env = isProduction ? 'production' : 'devOrStaging';
  let projectToken = new Map();
  projectToken.set('production', '9c176770ecfad527aaaf79dc6baf988f')
  projectToken.set('devOrStaging', '050fb576f2aa884646eecd31917d246e')
  const isDevelopment = location.hostname === 'localhost' || location.hostname === '127.0.0.1'
  const proxyHost = isDevelopment ? `http://${location.hostname}:3000` : `https://${location.hostname}`;
  mixpanel.init(projectToken.get(env), { api_host: proxyHost });
}

export {
  initMixPanel
}
