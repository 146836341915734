import axios from '../utils/axios-csrf'; // great ajax library.
import { Validator } from 'vee-validate';

const isAvailable = (value) => {
  return axios.get('/api/v2/public/authorizations/validate_email', { params: { email: value } })
    .then((response) => {
    // Notice that we return an object containing both a valid property and a data property.
    let message = "That email is invalid.";
    let data = response.data.data;
    if(data.valid) {
      message = data.available ? "" : "That email is taken.";
    }

    return {
      valid: data.valid && data.available,
      data: {
        message: message
      }
    };
  });
};

Validator.extend('investor-email-unique', {
  validate: isAvailable,
  getMessage: (field, params, data) => {
    return data.message;
  }
});

export default {};
