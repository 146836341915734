<template>
    <a @click.prevent="doAction" :disabled="isDisabled" :class="{ 'is-loading': loading }">
        <slot/>
    </a>
</template>

<script>

  export default {
    props: ['action','disabled'],
    data: () => {
      return {loading: false}
    },
    computed: {
      isDisabled() {
        return this.loading || this.disabled;
      }
    },
    methods: {
      click() {
        this.doAction();
      },
      doAction() {
        if(this.isDisabled) {
          return false;
        }
        this.action().then(
        () => {
          this.loading = false;
          return arguments;
        }
        ).catch(
        () => {
          this.loading = false;
        }
        );
        this.loading = true;
        return false;
      }
    }
  };
</script>
