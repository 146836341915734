module.exports = {
  ensureCheckboxDataNotEmpty(checkboxData) {
    return new Promise((resolve, reject) => {
      if (_(checkboxData).pickBy().isEmpty()) {
        alert("You must fill at least one checkbox");
        reject();
      } else {
        resolve(checkboxData);
      }
    });
  },
  mapCheckboxesToValuesFrom(checkboxes, fromMap) {
    return _(checkboxes).pickBy().mapValues((v, k) => {
      return fromMap[k];
    }).value();
  },
  redirectTo(url) {
    window.location.replace(url);
  }
};
