import Vue from 'vue';
import App from './login/app.vue';
import './utils/setup-buefy';
import './utils/bugsnag-client.js.erb';
import './vee-validators';
import VueSession from 'vue-session';
import VeeValidate from 'vee-validate';
import VueRouter from 'vue-router';
import VueCookies from 'vue-cookies';

import './styles/imby-pane-app';

document.addEventListener('DOMContentLoaded', () => {
  Vue.use(VueSession);
  Vue.use(VeeValidate);
  Vue.use(VueRouter);
  Vue.use(VueCookies);

  const router = new VueRouter({
      mode: 'history',
      routes: []
  });

  new Vue({
    el: '#login',
    render: h => h(App),
    router: router,
    created() {
      this.$session.start();
    },
    components: {
      App
    }
  });
});
